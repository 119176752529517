<template>
  <div :class="$style.container">
    <div @click="onBack" :class="$style.back">
      <svg
        :class="$style.icon"
        viewBox="0 0 20 20"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M7.9877 9.99981L13.2439 3.86753C13.4237 3.65787 13.3994 3.34222 13.1897 3.16251L12.8101 2.83711C12.6004 2.6574 12.2848 2.68168 12.1051 2.89135L6.291 9.67442C6.1305 9.86166 6.1305 10.138 6.291 10.3252L12.1051 17.1083C12.2848 17.3179 12.6004 17.3422 12.8101 17.1625L13.1897 16.8371C13.3994 16.6574 13.4237 16.3418 13.2439 16.1321L7.9877 9.99981Z"
          fill="#BCBCC2"
        />
      </svg>
    </div>

    <div :class="$style.title">
      <div :class="$style.text">
        <slot />
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'LayoutMobilePageHeader',
  props: {
    defaultPath: {
      type: String,
      default: '/tasks'
    }
  },
  methods: {
    onBack() {
      if (history.state.position === 1) {
        this.$router.push(this.$normalizePath(this.defaultPath))
      } else {
        this.$router.back()
      }
    }
  }
}
</script>

<style lang="scss" module>
.container {
  position: relative;
  margin-bottom: 2em;
}
.back {
  position: absolute;
  cursor: pointer;
  left: 0;
  top: 0;
  bottom: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 2em;
  height: 2em;
  margin: auto 0;
}
.title {
  text-align: center;
  .text {
    font-weight: 500;
    font-size: 1.8em;
    line-height: 189%;
    letter-spacing: -0.02em;
    color: #ffffff;
  }
}
.icon {
  width: 100%;
  height: 100%;
}
</style>
